import React from 'react'
import ReactDOM from 'react-dom/client'
import { NumerologyApp } from './App.tsx'
import * as Sentry from '@sentry/react'
import './index.css'

Sentry.init({
    dsn: 'https://bb1cfec38b912d2033036f400ef85071@o4504696324751360.ingest.us.sentry.io/4508285130375168',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/numerology\.astro-bistrot\.com/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <NumerologyApp />
    </React.StrictMode>,
)
