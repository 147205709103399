import { BrowserRouter as Router, useSearchParams } from 'react-router-dom'
import './App.css'
import { useNumerologyStore } from './NumerologyStore'
import { Fragment, useEffect } from 'react'

function Breadcrumb({ result }: { result?: number[] }) {
    return result ? (
        <div className="breadcrumb">
            {result.map((val, index) => (
                <Fragment key={`breadcrumb-${index}`}>
                    {index > 0 ? '→ ' : ''}
                    <span>{val}</span>
                </Fragment>
            ))}
        </div>
    ) : (
        '-'
    )
}

function App() {
    const [searchParams, setSearchParams] = useSearchParams()
    const state = useNumerologyStore()

    useEffect(() => {
        state.setDayOfBirth(searchParams.get('dayOfBirth') || '')
        state.setLastName(searchParams.get('lastName') || '')
        state.setFirstName1(searchParams.get('firstName1') || '')
        state.setFirstName2(searchParams.get('firstName2') || '')
        state.setFirstName3(searchParams.get('firstName3') || '')
        state.setFirstName4(searchParams.get('firstName4') || '')
    }, [searchParams])

    useEffect(() => {
        const params = new URLSearchParams()
        if (state.dayOfBirth) params.set('dayOfBirth', state.dayOfBirth)
        if (state.lastName) params.set('lastName', state.lastName)
        if (state.firstName1) params.set('firstName1', state.firstName1)
        if (state.firstName2) params.set('firstName2', state.firstName2)
        if (state.firstName3) params.set('firstName3', state.firstName3)
        if (state.firstName4) params.set('firstName4', state.firstName4)
        setSearchParams(params)
    }, [state])

    return (
        <div className="numerology">
            <img src="/logo-rond.png" alt="logo astro bistrot" className="logo" />
            <h1>Charte numérologique</h1>
            <p>
                Remplis ici tes informations de naissance : ton ou tes prénoms dans l'ordre de
                l'état civil, ton nom (de jeune fille si tu es une femme et mariée) et ta date de
                naissance.
            </p>

            <div className="buttons">
                <button
                    onClick={() => {
                        const searchParams = new URLSearchParams()
                        if (state.dayOfBirth) searchParams.set('date', state.dayOfBirth)
                        if (state.lastName) searchParams.set('last_name', state.lastName)
                        if (state.firstName1) searchParams.set('first_name_1', state.firstName1)
                        if (state.firstName2) searchParams.set('first_name_2', state.firstName2)
                        if (state.firstName3) searchParams.set('first_name_3', state.firstName3)
                        if (state.firstName4) searchParams.set('first_name_4', state.firstName4)
                        const url = `https://api.astrobistrot.com/numerology-pdf?${searchParams.toString()}`
                        window.open(url, '_blank')
                    }}
                >
                    Exporter en PDF
                </button>
            </div>

            <fieldset>
                <legend>Informations</legend>

                <div className="field">
                    <label htmlFor="dayOfBirth">Jour de naissance</label>
                    <input
                        id="dayOfBirth"
                        name="dayOfBirth"
                        type="date"
                        value={state.dayOfBirth}
                        onChange={(e) => state.setDayOfBirth(e.target.value)}
                        autoFocus
                    />
                </div>
                <div className="field">
                    <label htmlFor="lastName">Nom de famille</label>
                    <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        value={state.lastName}
                        onChange={(e) => state.setLastName(e.target.value)}
                    />
                </div>
                <div className="field">
                    <label htmlFor="firstName">Prénom 1</label>
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={state.firstName1}
                        onChange={(e) => state.setFirstName1(e.target.value)}
                    />
                </div>
                <div className="field">
                    <label htmlFor="firstName">Prénom 2</label>
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={state.firstName2}
                        onChange={(e) => state.setFirstName2(e.target.value)}
                    />
                </div>
                <div className="field">
                    <label htmlFor="firstName">Prénom 3</label>
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={state.firstName3}
                        onChange={(e) => state.setFirstName3(e.target.value)}
                    />
                </div>
                <div className="field">
                    <label htmlFor="firstName">Prénom 4</label>
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        value={state.firstName4}
                        onChange={(e) => state.setFirstName4(e.target.value)}
                    />
                </div>
            </fieldset>
            <fieldset className="results">
                <legend>Résultats</legend>
                <div className="field">
                    <p>Chemin de vie</p>
                    <p>
                        <Breadcrumb result={state.results.wayOfLife} />
                    </p>
                </div>
                <div className="field">
                    <p>Pierre angulaire</p>
                    <p>
                        <Breadcrumb result={state.results.angularStone} />
                    </p>
                </div>
                <div className="field">
                    <p>Nombre héréditaire</p>
                    <p>
                        <Breadcrumb result={state.results.hereditary} />
                    </p>
                </div>
                <div className="field">
                    <p>Année personnelle</p>
                    <p>
                        <Breadcrumb result={state.results.personalYear} />
                    </p>
                </div>
            </fieldset>
            <fieldset className="results">
                <legend>Résultats avec le prénom 1</legend>
                <div className="field">
                    <p>Nombre actif</p>
                    <p>
                        <Breadcrumb result={state.results.firstName1.active} />
                    </p>
                </div>
                <div className="field">
                    <p>Nombre intime</p>
                    <p>
                        <Breadcrumb result={state.results.firstName1.intimate} />
                    </p>
                </div>
                <div className="field">
                    <p>Nombre de réalisation</p>
                    <p>
                        <Breadcrumb result={state.results.firstName1.realization} />
                    </p>
                </div>
                <div className="field">
                    <p>Nombre d'expression</p>
                    <p>
                        <Breadcrumb result={state.results.firstName1.expression} />
                    </p>
                </div>
                <div className="field">
                    <p>Table d'inclusion</p>
                    <div className="inclusionGrid">
                        {state.results.inclusionTables.shortName.map((val, index) => (
                            <div key={`short-name-${index}`}>{val}</div>
                        ))}
                    </div>
                </div>
            </fieldset>
            <fieldset className="results">
                <legend>Résultats avec tous les prénoms</legend>
                <div className="field">
                    <p>Nombre intime</p>
                    <p>
                        <Breadcrumb result={state.results.allFirstNames.intimate} />
                    </p>
                </div>
                <div className="field">
                    <p>Nombre de réalisation</p>
                    <p>
                        <Breadcrumb result={state.results.allFirstNames.realization} />
                    </p>
                </div>
                <div className="field">
                    <p>Nombre d'expression</p>
                    <p>
                        <Breadcrumb result={state.results.allFirstNames.expression} />
                    </p>
                </div>
                <div className="field">
                    <p>Table d'inclusion</p>
                    <div className="inclusionGrid">
                        {state.results.inclusionTables.fullName.map((val, index) => (
                            <div key={`short-name-${index}`}>{val}</div>
                        ))}
                    </div>
                </div>
            </fieldset>

            <br></br>
            <h2>Comment analyser les résultats ?</h2>
            <p>
                Notre replay de 2h30 "Astrologie et numérologie, par Martine Barbault" au prix de
                10€ te permet d'interpréter cette charte numérologique et de faire un parallèle avec
                ton thème astral.
            </p>
            <br></br>
            <a href="mailto:astrobistrot@gmail.com?subject=Replay Astrologie et Numérologie&body=Bonjour, j'aimerais obtenir ce replay.">
                Tu peux le commander ici.
            </a>
        </div>
    )
}

export function NumerologyApp() {
    return (
        <Router>
            <App />
        </Router>
    )
}
